
$primary: #fa4516;
$body-color: #4f4f4f;

:root {
  --muted: #C9C9C9;
  --border-muted: #eee
}

@import "node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Bitter:wght@700&family=Roboto:wght@900&display=swap');

html {
  height: 100vh;
  height: -webkit-fill-available;
}
body {
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  height: 100vh;
  height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6, .btn {
  font-family: 'Roboto', sans-serif;
}

// dat.gui debug
.dg.ac {
  z-index: 1;
}

#react-root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  #hud {
    height: 100%;
    width: 100%;
  }

}

.displayNone {
  display: none;
}




