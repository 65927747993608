@import url('https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap');
$md: 769px !default;

.BuilderUI {
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-out;

  canvas {
    &:focus {
      outline: none;
    }

  }
}