.Stepper {
  // removes number in center of circle
  .MuiStepIcon-text {
    display: none;
  }

  // stepper styles won't work unless in here
  &.MuiStepper-root {
    padding: 5% 0px 0px 0px;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 7px;
  }
}