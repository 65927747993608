
$md: 769px !default;

.sceneLoadingScreen {

  background-color: rgb(0, 0, 0);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 10000;
  
  @media (max-width: $md), (orientation: portrait) {
    box-shadow:0 0 50px rgba(0, 0, 255, 0.15) inset;
  }
  box-shadow: 0 0 200px rgba(0, 0, 255, 0.15) inset;

  img {
    @media (max-width: $md), (orientation: portrait) {
      width: 100%;
    }
    width: 50%;
  }
}